import {DateTime} from 'luxon';

export const MAX_DATE = '2200-01-01T00:00:00+0000';
export const MIN_DATE = '1900-01-01T00:00:00+0000';

export interface MsDurationUnits {
  year?: number;
  month?: number;
  week?: number;
  day?: number;
  hour?: number;
  minute?: number;
  second?: number;
  millisecond?: number;
}

export type MsDurationUnit = keyof MsDurationUnits;

export interface IMsDate {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;

  set(duration: MsDurationUnits): IMsDate;

  plus(duration: MsDurationUnits): IMsDate;

  minus(duration: MsDurationUnits): IMsDate;

  diff(val: any, unit?: MsDurationUnit): number;

  startOf(unit: MsDurationUnit): IMsDate;

  endOf(unit: MsDurationUnit): IMsDate;

  locale(): string;

  toUTC(): IMsDate;

  toDateTimeISO(): string;

  toFormat(formatValue: string): string;

  toCST(formatValue: string): string;

  toDate(): string;

  toISO(): string;

  toMonthYear(): string;

  toFullMonthYear(): string;

  isToday(): boolean;

  isPast(unit: MsDurationUnit): boolean;

  isFuture(unit: MsDurationUnit): boolean;

  isBefore(val: DateTime, unit: MsDurationUnit): boolean;

  isSame(val: DateTime, unit: MsDurationUnit): boolean;

  isSameOrBefore(val: any, unit: MsDurationUnit): boolean;

  isAfter(val: DateTime, unit: MsDurationUnit): boolean;

  isSameOrAfter(val: any, unit: MsDurationUnit): boolean;
}

export interface MsDateOptions {
  zone?: string;
  format?: string;
}
